import { useForm } from 'react-hook-form'
import { i18n } from '../i18n'

/**
 * @typedef {object} SearchBoxPropsFields
 * @property {string} defaultValue
 * @property {Function} onSearch
 */

/**
 * @typedef {SearchBoxPropsFields} SearchBoxProps
 */

/**
 * @param {SearchBoxProps} props
 */
function SearchBox({ defaultValue, onSearch }) {
    const { register, handleSubmit } = useForm()
    /** @type { import('react-hook-form').SubmitHandler<import('react-hook-form').FieldValues> } */
    let onSubmit = ( values ) => {
        onSearch(values.search)
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <input className="text" type="search" defaultValue={defaultValue}
                placeholder={i18n('label.search')} {...register('search')} />
            <input data-testid="submit" type="submit" hidden />
        </form>
    )
}

export default SearchBox
