import { useEffect, useState } from 'react'

import { useSearchParams } from 'react-router'

import { i18n } from './i18n'
import { registerServiceWorker } from './helpers/Notifications'

const pages = ['main', 'password', 'about']

export const Settings = () => {
  const [params] = useSearchParams()
  const page = pages.includes(params.get('page')) ? params.get('page') : 'main'
  const { visitor, can_delete_telegram, telegram_name, jids, emails, email_active, fbstatus, twitter_name, userinfo } = window.state
  const [notificationsStatus, setNotificationsStatus] = useState('...')
  const buttonDisabled = window.Notification ? ['granted', 'denied'].includes(Notification.permission.toLowerCase()) : true
  const notificationsCheckPermissions = () => {
    switch (Notification.permission.toLowerCase()) {
      case 'granted':
        setNotificationsStatus(i18n('settings.notifications.granted'))
        registerServiceWorker()
        break
      case 'denied':
        setNotificationsStatus(i18n('settings.notifications.denied'))
        break
      case 'default':
        setNotificationsStatus(i18n('settings.notifications.request'))
    }
  }
  useEffect(() => {
    !!window.Notification && notificationsCheckPermissions()
    document.title = i18n('link.settings')
  }, [])
  switch (page) {
    case 'main': {
      return (
        <article className='page-content'>
          {
            !visitor.verified &&
            <h2 style={{ background: '#ff339a', color: 'white', padding: '20px', marginBottom: '20px' }}>Verify your account by adding email or social account</h2>
          }
          <h1>Settings</h1>
          {
            !!window.Notification &&
            <fieldset>
              <legend>Browser notifications</legend>
              <button className="Button" disabled={buttonDisabled} onClick={() => {
                Notification.requestPermission(() => { notificationsCheckPermissions() })
              }}>{notificationsStatus}</button>
            </fieldset>
          }
          <fieldset>
            <legend
              style={{
                background: 'url(/durov.png) no-repeat',
                paddingLeft: '58px',
                lineHeight: '48px',
                backgroundSize: 'contain'
              }}>
              Telegram</legend>
            {
              telegram_name ?
                <form action="/settings" method="POST">
                  <div>Telegram: <b>{telegram_name}</b> &mdash;
                    <input type="hidden" name="page" value="telegram-del" />
                    <input type="submit" value="  Disable  " disabled={!can_delete_telegram} className="Button" />
                  </div>
                </form>
                :
                <p>To connect Telegram account: send any text message to <a href="https://telegram.me/Juick_bot">@Juick_bot</a></p>
            }
          </fieldset>
          {
            jids && jids.length > 0 &&
            <form action="/settings" method="POST">
              <fieldset>
                <legend
                  style={{
                    background: 'url(//static.juick.com/settings/xmpp.png) no-repeat',
                    paddingLeft: '58px',
                    lineHeight: '48px'
                  }}>
                  XMPP accounts
                </legend>
                <p>Your accounts:</p>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                  {
                    jids.map(jid => {
                      return <label key={jid}><input type="radio" name="delete" value={`xmpp;${jid}`} />{jid}</label>
                    })
                  }
                </div>
                {jids.length > 1 &&
                  <p><input type="hidden" name="page" value="jid-del" /><input type="submit" className="Button"
                    value="  Delete  " /></p>
                }
              </fieldset>
            </form>
          }
          <fieldset>
            <legend
              style={{
                background: 'url(https://static.juick.com/settings/email.png) no-repeat',
                paddingLeft: '58px',
                lineHeight: '48px'
              }}>
              E-mail
            </legend>
            <form action="/settings" method="POST">
              <p>Add account:<br />
                <input type="text" name="account" />
                <input type="hidden" name="page" value="email-add" />
                <input type="submit" value="  Add  " className="Button" />
              </p>
            </form>
            <form action="/settings" method="POST">
              <p>Your accounts:</p>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                {emails && emails.map(email => {
                  return <label key={email}><input type="radio" name="account" value={email} />{email}</label>
                })
                }
              </div>
              {
                emails.length > 1 &&
                <p><input type="hidden" name="page" value="email-del" /><input type="submit" className="Button"
                  value="  Delete  " /></p>
              }
            </form>

            {
              emails &&
              <form action="/settings" method="POST">
                <p>You can receive notifications to email:<br />
                  Sent to <select name="account">
                    <option value="">Disabled</option>
                    {
                      emails.map(email => {
                        return <option key={email} value={email} selected={email_active == email}>
                          {email}
                        </option>
                      })
                    }
                  </select>
                  <input type="hidden" name="page" value="email-subscr" />
                  <input type="submit" className="Button" value="OK" />
                </p>
              </form>
            }
            <p>&nbsp;</p>
            <p>
              You can post to Juick via e-mail.
              Send your <span style={{ textDecoration: 'underline' }}>plain text</span> messages
              to <span><a href="mailto:juick@juick.com">juick@juick.com</a></span>.
              You can attach one photo or video file.</p>
          </fieldset>
          <fieldset>
            <legend
              style={{
                background: 'url(//static.juick.com/settings/facebook.png) no-repeat',
                paddingLeft: '58px',
                lineHeight: '48px'
              }}>
              Facebook
            </legend>
            {
              fbstatus.connected ?
                (
                  fbstatus.crosspostEnabled ?
                    <form action="/settings" method="POST">
                      <div>
                        Facebook: <b>Enabled</b> &mdash;
                        <input type="hidden" name="page" value="facebook-disable" />
                        <input type="submit" className="Button" value="  Disable  " />
                      </div>
                    </form>
                    :
                    <form action="/settings" method="POST">
                      <div>
                        Facebook: <b>Disabled</b> &mdash;
                        <input type="hidden" name="page" value="facebook-enable" />
                        <input type="submit" className="Button" value="  Enable  " />
                      </div>
                    </form>
                )
                :
                <p>Cross-posting to Facebook: <a href="/_fblogin"><img src="//static.juick.com/facebook-connect.png"
                  alt="Connect to Facebook" /></a></p>
            }
          </fieldset>
          <fieldset>
            <legend
              style={{
                background: 'url(//static.juick.com/settings/twitter.png) no-repeat',
                paddingLeft: '58px',
                lineHeight: '48px'
              }}>
              Twitter</legend>
            {
              twitter_name ?
                <form action="/settings" method="POST">
                  <div>Twitter: <b>{twitter_name}</b> &mdash;
                    <input type="hidden" name="page" value="twitter-del" />
                    <input type="submit" className="Button" value="  Disable  " />
                  </div>
                </form>
                :
                <p>Cross-posting to Twitter: <a href="/_twitter"><img src="//static.juick.com/twitter-connect.png"
                  alt="Connect to Twitter" /></a></p>
            }
          </fieldset>
        </article>
      )
    }
    case 'password': {
      return (
        <fieldset>
          <legend>Changing your password</legend>
          <form action="/settings" method="post">
            <input type="hidden" name="page" value="password" />
            <p>Change password: <input type="password" name="password" size={8} />
              <input className="Button" type="submit" value="  Update  " /><br />
              <i>(max. length - 16 symbols)</i></p>
          </form>
        </fieldset>
      )
    }
    case 'about': {
      return (
        <form action="/settings" method="POST" encType="multipart/form-data">
          <p>Full name: <input type="text" name="fullname" defaultValue={userinfo.fullname} /></p>
          <p>Country: <input type="text" name="country" defaultValue={userinfo.country} /></p>
          <p>URL: <input type="text" name="url" defaultValue={userinfo.url} size={32} /><br />
            <small>Please, start with &quot;http://&quot;</small></p>
          <p>About:<br />
            <input type="text" name="descr" defaultValue={userinfo.description} style={{ width: '100%' }} /><br />
            <small>Max. 255 symbols</small></p>
          <p>Avatar: <input type="file" name="newAvatar" /><br />
            <small>Recommendations: PNG, 96x96, &lt;50Kb. Also, JPG and GIF supported.</small></p>
          <p><input type="hidden" name="page" value="about" /><input className="Button" type="submit" value="  OK  " /></p>
        </form>
      )
    }
  }
}
