import { useState, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router'
import { getMessages } from './api'
import Spinner from './components/Spinner'
import Message from './components/Message'
import { i18n } from './i18n'

export const Main = () => {
  const [state, setState] = useState({
    msgs: [],
    nextpage: null,
    error: false,
    tag: ''
  })
  const [loading, setLoading] = useState(true)
  const [search] = useSearchParams()
  useEffect(() => {
    const paramShow = search?.get('show')
    const paramSearch = search?.get('search')
    const isDiscussions = !paramShow && !paramSearch
    const isMyFeed = paramShow && paramShow == 'my'
    const isTop = paramShow && paramShow == 'top'
    const isPhotos = paramShow && paramShow == 'photos'
    const isSearch = paramShow && paramShow == 'search'
    const isDiscover = paramShow && paramShow == 'all'

    const query = isDiscussions ? {
      baseUrl: '/api/messages/discussions',
      pageParam: 'to'
    } : isMyFeed ? {
      baseUrl: '/api/home',
      pageParam: 'before_mid'
    } : {
      baseUrl: '/api/messages',
      pageParam: paramSearch ? 'page' : 'before_mid'
    }

    setLoading(true)
    let getNextPageParamValue = (pageParam, lastMessage) => {
      return  pageParam === 'before_mid' ? lastMessage.mid : pageParam === 'page' ? +search.get('page') + 1 : new Date(lastMessage.updated).getTime()
    }
    let params = {  }
    let url = query.baseUrl
    if (isMyFeed) {
      document.title = i18n('link.my')
    }
    if (isDiscussions) {
      document.title = i18n('link.discuss')
    }
    if (isDiscover) {
      document.title = i18n('link.allMessages')
    }
    if (isTop) {
      params['popular'] = 1
      document.title = i18n('link.popular')
    }
    if (isPhotos) {
      params['media'] = 1
      document.title = i18n('link.withPhotos')
    }
    if (isSearch) {
      params['search'] = paramSearch
      document.title = `${i18n('label.search')}: ${paramSearch}`
    }    
    let pageParam = search?.get(query.pageParam)
    if (pageParam) {
      params[query.pageParam] = pageParam
    }
    getMessages(url, params)
      .then(response => {
        const { data } = response
        const { pageParam } = query
        const lastMessage = data.slice(-1)[0] || {}
        const nextValue = getNextPageParamValue(pageParam, lastMessage)
        const oldParams = search.toString()
        const nextPage = new URLSearchParams(oldParams)
        nextPage.set(pageParam, nextValue)
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        setState((prevState) => {
          return {
            ...prevState,
            msgs: data,
            nextpage: `?${nextPage.toString()}`,
            tag: search.get('tag') || ''
          }
        })
        setLoading(false)
      }).catch(() => {
        setState((prevState) => {
          return {
            ...prevState,
            error: true
          }
        })
      })
  }, [search])
  return (state.msgs.length > 0 ? (
    <div className="msgs">
      {
        state.tag && (
          <p className="page">
            <Link to={{ pathname: `/tag/${state.tag}` }}>
              <span>← All posts with tag&nbsp;</span><b>{state.tag}</b>
            </Link>
          </p>
        )
      }
      {
        state.msgs.map(msg =>
          <Message key={msg.mid} data={msg} />)
      }
      {
        state.msgs.length >= 20 && (
          <p className="page">
            <Link to={{ pathname: location.pathname, search: state.nextpage }} rel="prev">Next →</Link>
          </p>
        )
      }
    </div>
  ) : state.error ? <div>error</div> : loading ? <div className="msgs"><Spinner /><Spinner /><Spinner /><Spinner /></div> : <div>No more messages</div>
  )
}
